import { useMutation, useQuery } from '@tanstack/react-query';
import useAlert from 'hooks/useAlert';
import useBlockUi from 'hooks/useBlockUi';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import { ResponsePage } from 'types/common/response';
import { Search } from 'types/common/search';
import { ReturnModel, TableActionRowsObj, TableContextProps, TableTemplateProp } from 'types/common/table';
import { handleInitialValues } from 'utils/form';

const initSearch: Search<any, any> = { page: 1, rows: 10, total_rows: 0, filter: {}, datas: [] };
const initResponse: ResponsePage<any> = {
  code: 200,
  message: '',
  success: false,
  meta: { total_rows: 0 },
  payload: []
};

type TableProviderProps = {
  children: ReactNode;
} & TableTemplateProp;

const initialState: TableContextProps = {
  search: initSearch,
  response: initResponse,
  handleRefresh: () => {},
  handlePage: () => {},
  handlePageSize: () => {},
  handleSearch: () => {},
  handleFilterChange: () => {}
};

const TableContext = createContext(initialState);

function TableConfigProvider({
  type,
  key_column,
  base_path,
  name,
  api,
  children,
  call_back,
  action_rows,
  action_tops,
  filterUpdate,
  model,
  isAlert
}: TableProviderProps) {
  const [search, setSearch] = useState<Search<any, any>>(initSearch);
  const [masters, setMasters] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [text, setText] = useState('');
  const [form, setForm] = useState({});
  const block = useBlockUi();
  const router = useRouter();
  const { alertError, alertSuccess } = useAlert();

  const { data, fetchStatus, refetch } = useQuery([`search_${name}`, search], () => api.fetchSearch(search), {
    keepPreviousData: true,
    refetchOnWindowFocus: false
    // enabled: false
  });

  const { data: mastersData, fetchStatus: fetchStatusMaster } = useQuery(
    [`init_${name}`],
    () => (api.fetchInit ? api.fetchInit() : new Promise((r, e) => r([]))),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const exportMute = useMutation((filter: any) => api.fetchExport && api.fetchExport(filter));
  const deleteMute = useMutation((row: any) => api.deleteFunc && api.deleteFunc(row));
  const saveMute = useMutation((row: any) => api.patchUpdate && api.patchUpdate(row));
  const downloadMute = useMutation((row: any) => api.downloadFunc && api.downloadFunc(row));
  const copyMute = useMutation((row: any) => api.copyFunc && api.copyFunc(row));

  useEffect(() => {
    block.handleLoad(true);
    setMasters(mastersData);
  }, [mastersData]);

  useEffect(() => {
    if (filterUpdate) {
      search.filter = { ...search.filter, filterUpdate };
      setSearch({ ...search });
    } else {
      search.filter = {};
      setSearch({ ...search });
    }
  }, [filterUpdate]);

  if (fetchStatus === 'idle' && fetchStatusMaster === 'idle') {
    block.handleLoad(false);
  }

  const handlePage = (e: any, page: number) => {
    block.handleLoad(true);
    // console.log(search);
    //
    setSearch({ ...search, page });
  };

  const handlePageSize = (rows: number) => {
    block.handleLoad(true);
    setSearch({ ...search, rows });
  };

  const handleFilterChange = (filter: any, page: number) => {
    block.handleLoad(true);
    // console.log(filter, page);
    const currentPage: any = page === undefined ? 1 : page;
    setSearch({ ...search, filter, currentPage });
  };

  const handleRefresh = () => {
    console.log('refresh data table');
    refetch();
  };

  const handleSearch = useCallback((search: Search<any, any>) => {
    block.handleLoad(true);
    // console.log(search)
    setSearch(search);
  }, []);

  const handleExport = useCallback((filter: any) => {
    // setSearch(filter)
    exportMute.mutateAsync({ filter: filter });
  }, []);

  const handleBtnImport = useCallback(() => {
    // exportMute.mutateAsync(search);
    handleRefresh();
    setOpenImportModal(true);
  }, []);

  const handleImport = useCallback(async (result: any) => {
    try {
      if (result.submit && result.data) {
        // const copyResult: any =
        // await copyMute.mutateAsync(result.data);
        setOpenImportModal(false);
        refetch();
        // alertSuccess(copyResult?.message || 'คัดลอกข้อมูลสำเร็จ');
      } else {
        setOpenImportModal(false);
      }
    } catch (error: any) {
      alertError(error?.response?.data?.message || 'คัดลอกข้อมูลไม่สำเร็จ');
    }
  }, []);

  const handleNew = useCallback(() => {
    const btn = action_tops?.find((p) => p.type === 'add');
    if (btn) {
      setText(btn.label ?? 'เพิ่มข้อมูล');
    } else {
      setText('เพิ่มข้อมูล');
    }
    if (type == 'page') {
      if (name === 'ShiftWork') {
        setOpenModal(true);
      }
      call_back?.callBackNew && call_back?.callBackNew(true);
    } else if (type === 'model' || type === 'drawer') {
      setOpenModal(true);
      setForm(handleInitialValues(model?.inputs || []));
      call_back?.callBackNew && call_back?.callBackNew(true);
    } else {
      call_back?.callBackNew && call_back?.callBackNew();
    }
  }, []);

  const handleUpdate = useCallback(async (row: any) => {
    const btn = action_rows?.find((p) => (p as TableActionRowsObj).type === 'edit');
    if (btn) {
      setText((btn as TableActionRowsObj).text ?? 'แก้ไขข้อมูล' + (row?.name ? row.name : ''));
    } else {
      setText('แก้ไขข้อมูล' + (row?.name ? row.name : ''));
    }
    if (call_back?.callBackUpdate) {
      call_back?.callBackUpdate(row);
      if (type === 'drawer') {
        setForm(row);
        setOpenModal(true);
      }
    } else if (type === 'model' || type === 'drawer') {
      if (api.fetchOneData) {
        const data = await api.fetchOneData(row);
        setForm(data);
        setOpenModal(true);
      } else {
        setForm(row);
        setOpenModal(true);
      }
    } else {
      router.push(`${base_path}/${row[key_column ?? 'id']}`);
    }
  }, []);

  const handleBtnDelete = useCallback((row: any) => {
    const action = action_rows?.find((p) => (p as TableActionRowsObj).type === 'delete');
    if (call_back?.callBackDelete) {
      call_back?.callBackDelete(row);
      if (type === 'drawer') {
        setForm(row);
        setOpenModal(true);
      }
    } else {
      if (action) {
        const subText = (action as TableActionRowsObj).text
          ? (action as TableActionRowsObj).text + ' ' + row[(action as TableActionRowsObj).use_name]
          : row[(action as TableActionRowsObj).use_name];
        setText(subText);
      } else {
        setText('ลบรายการ');
      }
      setOpenDeleteModal(true);
      setForm(row);
    }
  }, []);

  const handleDelete = useCallback(async (result: any) => {
    try {
      if (result.submit && result.data) {
        const deleteResult: any = await deleteMute.mutateAsync(result.data);
        setOpenDeleteModal(false);
        refetch();
        alertSuccess(deleteResult?.message || 'บันทึกข้อมูลสำเร็จ');
      } else {
        setOpenDeleteModal(false);
      }
    } catch (error: any) {
      alertError(error?.response?.data?.message || 'ลบข้อมูลไม่สำเร็จ');
    }
  }, []);

  const handleBtnCopy = useCallback((row: any) => {
    const action = action_rows?.find((p) => (p as TableActionRowsObj).type === 'copy');
    if (action) {
      const subText = (action as TableActionRowsObj).text
        ? (action as TableActionRowsObj).text + ' ' + row[(action as TableActionRowsObj).use_name]
        : row[(action as TableActionRowsObj).use_name];
      setText(subText);
    } else {
      setText('คัดลอกรายการ');
    }
    setOpenCopyModal(true);
    setForm(row);
  }, []);

  const handleCopy = useCallback(async (result: any) => {
    try {
      if (result.submit && result.data) {
        // const copyResult: any =
        await copyMute.mutateAsync(result.data);
        setOpenCopyModal(false);
        refetch();
        // alertSuccess(copyResult?.message || 'คัดลอกข้อมูลสำเร็จ');
      } else {
        setOpenCopyModal(false);
      }
    } catch (error: any) {
      alertError(error?.response?.data?.message || 'คัดลอกข้อมูลไม่สำเร็จ');
    }
  }, []);

  const handleDownload = useCallback((row: any) => {
    downloadMute.mutate(row);
  }, []);

  const handleSubmitModal = useCallback(async ({ id, data, submit }: ReturnModel) => {
    try {
      if (submit) {
        const submitResult: any = await saveMute.mutateAsync({ id, data });
        setOpenModal(false);
        console.log('isAlert', isAlert);
        if (isAlert !== false) {
          alertSuccess(submitResult?.message || 'บันทึกข้อมูลสำเร็จ');
        }
        refetch();
      } else {
        setOpenModal(false);
      }
    } catch (error: any) {
      alertError(error?.response?.data?.message || 'บันทึกข้อมูลไม่สำเร็จ');
    }
  }, []);

  const handleView = useCallback((row: ReturnModel) => {
    if (call_back?.callBackView) {
      call_back?.callBackView(row);
    }
  }, []);

  const value: TableContextProps = {
    search,
    form,
    masters,
    openModal,
    text,
    handleRefresh,
    openDeleteModal,
    openCopyModal,
    openImportModal,
    response: data,
    handlePage,
    handlePageSize,
    handleFilterChange,
    handleSearch,
    handleNew,
    handleExport,
    handleImport,
    handleUpdate,
    handleDelete,
    handleBtnDelete,
    handleDownload,
    handleSubmitModal,
    handleView,
    handleCopy,
    handleBtnCopy,
    handleBtnImport
  };
  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
}

export { TableConfigProvider, TableContext };
