// material-ui
import { Box, CardMedia } from '@mui/material';
import { Theme } from '@mui/material/styles';

// project import
import MainCard, { MainCardProps } from 'components/MainCard';
// ==============================|| AUTHENTICATION - CARD WRAPPER ||============================== //
const image = '/assets/images/login/login.png';

const AuthCard = ({ children, imageLogo, ...other }: MainCardProps & { imageLogo?: string }) => (
  <MainCard
    sx={{
      maxWidth: '60%',
      margin: { xs: 2.5, md: 3 },
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'column', md: 'row' },
      alignItems: 'center'
    }}
    content={false}
    {...other}
    border={false}
    boxShadow
    shadow={(theme: Theme) => theme.customShadows.z1}
  >
    <CardMedia
      sx={{
        display: { xs: 'none', md: 'block' },
        padding: '40px', // Add some padding
        maxWidth: '50%'
      }}
      component="img"
      image={imageLogo ?? image}
      title="auth"
    />
    <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 6 }, mt: 2 }}>{children}</Box>
  </MainCard>
);

export default AuthCard;
