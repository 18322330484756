import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

export default function useAlert() {
  return {
    alertSuccess: (message: string, variant = 'alert', close = true) => {
      dispatch(
        openSnackbar({
          open: true,
          message,
          variant,
          alert: {
            color: 'success'
          },
          close
        })
      );
    },
    alertError: (message: string, variant = 'alert', close = true) => {
      dispatch(
        openSnackbar({
          open: true,
          message,
          variant,
          alert: {
            color: 'error'
          },
          close
        })
      );
    },
    alertWarning: (message: string, variant = 'alert', close = true) => {
      dispatch(
        openSnackbar({
          open: true,
          message,
          variant,
          alert: {
            color: 'warning'
          },
          close
        })
      );
    },
    alertInfo: (message: string, variant = 'alert', close = true) => {
      dispatch(
        openSnackbar({
          open: true,

          message,
          variant,
          alert: {
            color: 'info'
          },
          close
        })
      );
    }
  };
}
