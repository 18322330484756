import { Box } from '@mui/material';

const AuthBackground = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '65%',
        filter: 'blur(18px)',
        zIndex: -1,
        left: '40%', // Adjust for alignment relative to screen center
        transform: 'translate(-50%, -50%)', // Centers the image vertically and horizontally
        width: '100%', // Adjust to make the image spill off-screen horizontally
        height: '100%', // Maintain aspect ratio
        overflow: 'hidden' // Ensure only part of the image is visible
      }}
    >
      <img src="/assets/images/logo/cw-logo.png" alt="Logo" height="75%" />
    </Box>
  );
};

export default AuthBackground;

//<Box sx={{ position: 'absolute', filter: 'blur(18px)', zIndex: -1, bottom: 0 }}></Box>
